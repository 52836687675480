<div class="modal">
  <div class="modal-content p-0 flex flex-col gap-5 w-[500px] pb-3">
    <div class="modal-header">
      <div class="flex flex-row items-center">
        <i class="ph ph-cloud-x mr-4"></i>
        <h2 class="text-xl">{{errorHeaders[data.error.status]}}</h2>
      </div>
      <label
        (click)="close(null)"
        class="btn btn-sm btn-circle btn-ghost right-2 top-0 mr-[1.5rem] ml-[0.5rem]"
      >✕</label
      >
    </div>
    <div class="divider divider-horizontal"></div>
    <div class="modal-body">
      <p class="sub-title4 text-black">{{errorMessage}}</p>
    </div>
    <div class="modal-footer">
      <button (click)="close(null)" class="btn btn-outline-secondary btn-sm cancelBtn hover:bg-error">
        <label class="cursor-pointer visually-hidden">Close</label>
      </button>
    </div>
  </div>
</div>
