import { Component } from '@angular/core';
import {BaseModalComponent} from "../../base/base-modal/base-modal.component";
import {HttpErrorResponse} from "@angular/common/http";

export interface ErrorHandlerModalInput{
  error:HttpErrorResponse
}

export interface ErrorHandlerModalOutPut {

}

@Component({
  selector: 'app-error-handler-modal',
  templateUrl: './error-handler-modal.component.html',
  styleUrls: ['./error-handler-modal.component.scss']
})
export class ErrorHandlerModalComponent extends BaseModalComponent<ErrorHandlerModalInput, ErrorHandlerModalOutPut>{
  public errorMessage: string;
  public errorHeaders:Record<number, string> = {
    401: 'Credentials Error',
    403: 'Permission Error',
    404: 'Not Found',
    400: 'Oops Issue',
    504: 'Server Error',
    500: 'Server Error',
  }
  constructor() {
    super();
  }

  async onOpen(): Promise<void> {
    super.onOpen();
    if(this.data.error.status.toString().startsWith('5')){
      this.errorMessage = 'Our server are having some issues, please try again later'
      return
    }
    this.errorMessage = this.data.error.error?.detail
  }


}
