import {ErrorHandler, Injectable} from '@angular/core';
import {SentryErrorHandler} from "@sentry/angular-ivy";

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {
  constructor() {
    super({
      showDialog: true,
    });
  }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk .*failed.*[.js\\)]/;
    const apiErrorMessage = /api/; // Adjust this regex to match your API error messages
    if (chunkFailedMessage.test(error.message)) {
      if (confirm('New version available. Load New Version?')) {
        window.location.reload();
      }
    } else if (error && error.rejection) {
      if (error.rejection && apiErrorMessage.test(error.rejection.url)) {
        if (error.rejection.status === 500) {
          super.handleError(error);
        } else {

        }
      } else {
        super.handleError(error);
      }
    } else {
      if (error.status === 401 && error.url.includes('/auth/login')) {
        return;
      }
      if(error.status === 500 && error.url.includes('/auth/forgot-my-password/')){
        return;
      }
        super.handleError(error);
      }
    }
}
