import {Injectable} from '@angular/core';
import * as moment from 'moment-timezone';


import {TimeZoneState} from "../states/global-state-services/time-state.service";
import {Moment} from "moment";

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {
  DEFAULT_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZZ';
  FULL_DISPLAY_FORMAT = 'YYYY-MM-DD HH:mm (Z)';
  SIMPLE_FORMAT = 'YYYY-MM-DD';
  TIME_LOCAL = 'YYYY-MM-DDTHH:mm'
  DATE_TIME_FRIENDLY = 'MMMM Do, YYYY [at] h:mm a';
  HOURS_MINUTES_AM_PM = 'hh:mm a'
  HOUR_MINUTE = 'HH:mm'
  DATE_HOUR= 'YYYY-MM-DD H'
  public timeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone

  constructor(private timeState: TimeZoneState) {

    this.timeState.timeZone$.subscribe(
      (timeZone) => {
        this.timeZone = timeZone ? timeZone : Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
    );
  }

  public dateToTimeLocal(date: Date): string {
    return moment(date).format(this.TIME_LOCAL);
  }

  public timezoneList(): string[] {
    return moment.tz.names();
  }

  public dayAndTimeToToLocalWeekDay(day: string, hour: number, weekToStart?:Moment): Moment {
    const transferHash: { [key: string]: string } = {
      'sun': 'Sunday',
      'mon': 'Monday',
      'tue': 'Tuesday',
      'wed': 'Wednesday',
      'thu': 'Thursday',
      'fri': 'Friday',
      'sat': 'Saturday'
    }
    let now = moment();
    let result = now.clone().day(transferHash[day]).hours(hour).minutes(0).seconds(0).milliseconds(0);
    if(weekToStart){
      result = result.week(weekToStart.week()).year(weekToStart.year());
    }
    return result.tz(this.timeZone);
  }
  public formatTimeForBackend(date: string, time: string): string {
    return moment.tz(date + 'T' + time, this.timeZone).utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSSZZ');
  }
  public dateHourToMoment(dateHour: string): Moment {
    return moment.tz(dateHour, this.DATE_HOUR, this.timeZone)
  }

  public hoursToAmPm(hours: string | number): string {
    return moment(hours, 'HH').format('ha');
  }

  public amPmToHours(hours: string): number {
    return moment(hours, 'ha').hour();
  }

  public dateToDefaultStr(date: Date | string): string {
    return moment(date).utc().format(this.DEFAULT_FORMAT);
  }



  public dateToDateStr(date: Date): string {
    return moment(date).format(this.SIMPLE_FORMAT);
  }

  public stringToDateStr(date: string): string {
    return moment(date).format(this.SIMPLE_FORMAT);
  }

  public dateToDateWithFormat(date: Date | string, format:string): string {
    return moment(date).format(format);
  }


  public defaultStrToMoment(dateStr: string): Moment {
    return moment(dateStr);
  }

  public dateToDefaultString(date: Date): string {
    let momentObj = moment(date);
    momentObj.tz(this.timeZone, true).utc();
    return momentObj.format(this.DEFAULT_FORMAT);
  }

  public defaultStrToMomentTimeZone(dateStr: string): Moment {
    return moment.tz(dateStr, this.timeZone);
  }

  public defaultStrToMomentDateTime(dateStr: string): string {
    const momentObj = moment.tz(dateStr, this.timeZone);
    return momentObj.format(this.DATE_TIME_FRIENDLY);
  }

  public momentToAmPmStr(momentObj: Moment): string {
    const mom = moment.tz(momentObj, this.timeZone)
    return mom.format(this.HOURS_MINUTES_AM_PM);
  }


  public defaultStrToMomentTimeAmPm(dateStr: string): string {
    const momentObj = moment(dateStr);
    return momentObj.format(this.HOURS_MINUTES_AM_PM);
  }


  defaultStrToMomentTime(dateStr: string): string {
    const momentObj = moment(dateStr);
    return momentObj.format(this.HOUR_MINUTE);
  }

  public simpleStrToDate(dateStr: string): Date {
    return new Date(dateStr);
  }

  public defaultStrToDate(dateStr: string): Date {
    if (!dateStr) {
      return null;
    }
    const momentObj = moment.tz(dateStr, this.timeZone);
    return this.simpleStrToDate(momentObj.format(this.FULL_DISPLAY_FORMAT));
  }


  public defaultStrToDisplayDate(dateStr: string): string {
    const startMoment = this.defaultStrToMoment(dateStr);
    return startMoment.format('MMMM Do, YYYY');
  }

  public defaultStrToSimpleDisplayDate(dateStr: string): string {
    const startMoment = this.defaultStrToMoment(dateStr);
    return startMoment.format('MM/DD/YYYY');
  }

  public defaultStrToFullDateDisplay(dateStr: string): string {
    if (!dateStr) {
      return '';
    }
    const startMoment = this.defaultStrToMoment(dateStr);
    return startMoment.format(this.FULL_DISPLAY_FORMAT);
  }

  public defaultStrToFullDateDisplayAtTimezone(dateStr: string, timezoneOffset: number): string {
    if (!dateStr) {
      return '';
    }
    let startMoment = this.defaultStrToMoment(dateStr).utcOffset(timezoneOffset);
    return startMoment.format(this.FULL_DISPLAY_FORMAT);
  }

  public defaultStrToFullDateDisplayUTC(dateStr: string): string {
    if (!dateStr) {
      return '';
    }
    const startMoment = this.defaultStrToMoment(dateStr).utc();
    return startMoment.format(this.FULL_DISPLAY_FORMAT);
  }

  public defaultStrToHourDisplay(dateStr: string): string {
    const startMoment = this.defaultStrToMoment(dateStr);
    return startMoment.format('h:mm A');
  }

  public getTimeZoneNumberStr(): string {
    return moment().format('Z')
  }

  public getTimeZoneOffset(): number {
    return moment().utcOffset() / 60;
  }

  public now(): Moment {
    return moment();
  }

  getDiffHours(startMoment: Moment, endMoment: moment.Moment): number {
    const duration = moment.duration(endMoment.diff(startMoment));
    return duration.asHours();
  }

  getDiffMinutes(startMoment: Moment, endMoment: moment.Moment): number {
    const duration = moment.duration(endMoment.diff(startMoment));
    return duration.asMinutes();
  }

  getHoursBeforeFromNow(startMoment: Moment): number {
    return this.getDiffHours(startMoment, this.now());
  }

  getMinutesBeforeFromNow(startMoment: Moment): number {
    return this.getDiffMinutes(startMoment, this.now());
  }

  getHoursAfterFromNow(endMoment: Moment): number {
    return this.getDiffHours(this.now(), endMoment);
  }

  getMinutesAfterFromNow(endMoment: Moment): number {
    return this.getDiffMinutes(this.now(), endMoment);
  }

  simpleStrToDisplayDate(dateStr: string): string {
    const startMoment = this.defaultStrToMoment(dateStr);
    return startMoment.format('MMMM Do, YYYY');
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return moment(date1).isSame(date2, 'day');
  }

  simpleStrsToRangeStr(dateStr1: string, dateStr2: string): string {
    const moment1 = this.defaultStrToMoment(dateStr1);
    const moment2 = this.defaultStrToMoment(dateStr2);
    if (moment1.year() === moment2.year()) {
      if (moment1.month() === moment2.month()) {
        return `${moment2.format('MMMM')} ${moment1.format('Do')} - ${moment2.format('Do, YYYY')}`;
      }
      return `${moment1.format('MMMM Do')} - ${moment2.format('MMMM Do, YYYY')}`;
    }
    return `${moment1.format('MMMM Do, YYYY')} - ${moment2.format('MMMM Do, YYYY')}`;
  }

  dateAndHourToMoment(date: Date, hour: string): Moment {
    let fullMoment = moment(date);
    const [hourStr, minuteStr] = hour.split(':');
    fullMoment = fullMoment.set({hour: +hourStr, minute: +minuteStr});
    return fullMoment;
  }

  dateToSimpleDate(date: Date): string {
    const momentObj = moment(date);
    return momentObj.format('YYYY-MM-DD');
  }

  getTimeOldStringFromDefaultString(dateStr: string): string {
    const dateMoment = this.defaultStrToMoment(dateStr);
    let minutesOld = Math.floor(this.getMinutesBeforeFromNow(dateMoment));
    if (minutesOld < 1) {
      minutesOld = 1;
    }
    if (minutesOld < 60) {
      return minutesOld === 1 ? '1 minute' : `${minutesOld} minutes`;
    }
    const hoursOld = Math.floor(minutesOld / 60);
    if (hoursOld < 24) {
      return hoursOld === 1 ? '1 hour' : `${hoursOld} hours`;
    }
    const daysOld = Math.floor(hoursOld / 24);
    return daysOld === 1 ? '1 day' : `${daysOld} days`;
  }

  getIsBeforeToday(dateStr: string): boolean {
    const dateMoment = this.defaultStrToMoment(dateStr);
    return dateMoment.isBefore(moment(), 'day');
  }
}
