import {Component} from '@angular/core';
import {BaseModalComponent} from "../../base/base-modal/base-modal.component";
import {FormControl, Validators} from '@angular/forms';

export interface RoundNumberInputModalInput {
  header: string;
  text: string;
  cancelActionName?: string;
  confirmActionName?: string;
  defaultValue?: number;
  allowEmpty?: boolean;
  placeholder?: string;
  subText?: string;
  titleNumber?: string;
  roundActionName?: string;
  roundMultiplierLimit?: number;
}

@Component({
  selector: 'app-round-number-input-modal',
  templateUrl: './round-number-input-modal.component.html',
  styleUrls: ['./round-number-input-modal.component.scss']
})

export class RoundNumberInputModalComponent extends BaseModalComponent<RoundNumberInputModalInput, number> {
  public cancelActionName: string;
  public roundActionName: string;
  public isRoundNumber: boolean = true;
  public isCapNumber: boolean = false;
  public confirmActionName: string;
  public formCtrl = new FormControl<number>(0);
  placeholder: string = '';

  ngOnInit() {
    this.formCtrl.valueChanges.subscribe(value => {
      this.checkIsRoundNumber()
      this.checkNumberLimit()
    });
  }

  async onOpen() {
    this.roundActionName= this.data.roundActionName || 'Round';
    this.cancelActionName = this.data.cancelActionName || 'Cancel';
    this.confirmActionName = this.data.confirmActionName || 'Confirm';
    if (this.data.defaultValue) {
      this.formCtrl.setValue(this.data.defaultValue);
    }
    if (!this.data.allowEmpty) {
      this.formCtrl.setValidators([Validators.required]);
    }
    this.placeholder = this.data.placeholder || '';
  }

  public async onSubmit() {
    if (!this.formCtrl.valid) {
      return
    }
    await this.close(this.formCtrl.value);
  }

  public onRoundButtonClick() {
    let numberToRound= this.formCtrl.value;
    let roundedNumber = Math.round(numberToRound / this.data.defaultValue) * this.data.defaultValue;
    this.formCtrl.setValue(roundedNumber)
  }

  public checkIsRoundNumber(){
    this.isRoundNumber = this.formCtrl.value % this.data.defaultValue === 0;
  }

  public checkNumberLimit(){
    this.isCapNumber= this.formCtrl.value > this.data.defaultValue * this.data.roundMultiplierLimit
  }
}
