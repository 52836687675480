import {Component} from '@angular/core';
import {BaseModalComponent} from "../../base/base-modal/base-modal.component";
import {FormControl, Validators} from '@angular/forms';

export interface NumberInputModalInput {
  header: string;
  text: string;
  cancelActionName?: string;
  confirmActionName?: string;
  defaultValue?: number;
  allowEmpty?: boolean;
  placeholder?: string;
}

@Component({
  selector: 'app-number-input-modal',
  templateUrl: './number-input-modal.component.html',
  styleUrls: ['./number-input-modal.component.scss']
})
export class NumberInputModalComponent extends BaseModalComponent<NumberInputModalInput, number> {
  public cancelActionName: string;
  public confirmActionName: string;
  public formCtrl = new FormControl<number>(0);
  placeholder: string = '';


  async onOpen() {
    this.cancelActionName = this.data.cancelActionName || 'Cancel';
    this.confirmActionName = this.data.confirmActionName || 'Confirm';
    if (this.data.defaultValue) {
      this.formCtrl.setValue(this.data.defaultValue);
    }
    if (!this.data.allowEmpty) {
      this.formCtrl.setValidators([Validators.required]);
    }
    this.placeholder = this.data.placeholder || '';
  }

  public async onSubmit() {
    if (!this.formCtrl.valid) {
      return
    }
    await this.close(this.formCtrl.value);
  }
}
