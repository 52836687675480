import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {AuthenticationService} from './authentication.service';
import {Observable, Subscriber} from 'rxjs';
import {LoginResponse} from '../models/users/login-response';
import {RoutingService} from '../services/routing.service';

@Injectable()
export abstract class RoleGuard  {

  constructor(private routingService: RoutingService, private authService: AuthenticationService) {
  }

  abstract userHasRole(loginResponse: LoginResponse): boolean;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    const loginResponse = this.authService.loginResponse
    const params = route.queryParams;
    const redirectUrl = state.url
    if (!!loginResponse) {
      if (this.userHasRole(loginResponse)) {
        return true;
      } else {
        this.authService.redirectUrl = redirectUrl;
        this.routingService.navigateToClientLogin();
        return false;
      }
    }
    if (route.queryParams?.['token']) {
      return this.getLoginObservableWithToken(redirectUrl, route.queryParams?.['token']);
    } else {
      return this.getLoginObservable(redirectUrl);
    }
  }

  getLoginObservable(redirectUrl: string): Observable<boolean> {
    return new Observable((sub) => {
      this.authService.loginResponse$.subscribe((loginResponse: LoginResponse) => {
        if (!!loginResponse) {
          if (this.userHasRole(loginResponse)) {
            sub.next(true);
          } else {
            this.goToLogin(sub, redirectUrl);
          }
        }
      });
    });
  }

  getLoginObservableWithToken(redirectUrl: string, token: string): Observable<boolean> {
    return new Observable((sub) => {
      this.authService.updateIsLoggedInWithToken(token);
      this.authService.tokenLoginResponse$.subscribe((loginResponse: LoginResponse) => {
        if (!!loginResponse) {
          if (this.userHasRole(loginResponse)) {
            sub.next(true);
          } else {
            this.goToLogin(sub, redirectUrl);
          }
        }
      });
    });
  }

  goToLogin(sub: Subscriber<boolean>, redirectUrl: string): void {
    this.authService.redirectUrl = redirectUrl;
    this.routingService.navigateToClientLogin();
    sub.next(false);
  }
}
