import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BaseModalComponent} from "../../base/base-modal/base-modal.component";

export interface ConfirmationModalInput {
  header: string;
  text: string;
  cancelActionName?: string;
  confirmActionName?: string;
  asyncConfirmOperation?: () => Promise<any>;
  asyncCancelOperation?: () => Promise<any>;
}

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent extends BaseModalComponent<ConfirmationModalInput, boolean> {
  public cancelActionName: string;
  public confirmActionName: string;
  public loading: boolean = false;

  ngOnInit(): void {
    this.cancelActionName = this.data.cancelActionName || 'Cancel';
    this.confirmActionName = this.data.confirmActionName || 'Confirm';
  }

  public async handleConfirm() {
    if(this.data.asyncConfirmOperation) {
      this.loading = true;
      await this.data.asyncConfirmOperation();
    }
    await this.close(true)
  }

  public async handleCancel() {
    if(this.data.asyncCancelOperation) {
      this.loading = true;
      await this.data.asyncCancelOperation();
    }
    await this.close(false)
  }

}
