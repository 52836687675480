import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {NgxPermissionsService} from 'ngx-permissions';
import {User} from '../models/users/user';
import {LoginResponse} from '../models/users/login-response';
import {RoutingService} from '../services/routing.service';
import {AdminData} from '../models/users/admin-data';
import {Persona} from '../models/users/persona';
import { TimeZoneState } from '../states/global-state-services/time-state.service';
import { tap } from 'rxjs/operators';
import {UserWebsocketsService} from "../states/global-state-services/user-websockets.service";
import * as Sentry from '@sentry/angular-ivy';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly _authMessageSub = new BehaviorSubject<string>(null);
  readonly authMessage$ = this._authMessageSub.asObservable();

  private readonly _authUserSub = new BehaviorSubject<User>(null);
  readonly authUser$ = this._authUserSub.asObservable();

  private readonly _isAuthSub = new BehaviorSubject<boolean>(null);
  readonly isAuth$ = this._isAuthSub.asObservable();

  private readonly _loginResponseSub = new BehaviorSubject<LoginResponse>(null);
  readonly loginResponse$ = this._loginResponseSub.asObservable();

  private readonly _tokenLoginResponseSub = new BehaviorSubject<LoginResponse>(null);
  readonly tokenLoginResponse$ = this._tokenLoginResponseSub.asObservable();

  private readonly _adminDataSub = new BehaviorSubject<AdminData>(null);
  readonly adminData$ = this._adminDataSub.asObservable();

  private readonly _contactPersonaSub = new BehaviorSubject<Persona>(null);
  readonly contactPersona$ = this._contactPersonaSub.asObservable();

  public redirectUrl: string = null;

  constructor(private http: HttpClient,
              private userWebsocketsService: UserWebsocketsService,
              private routingService: RoutingService,
              private permissionsService: NgxPermissionsService,
              private timeState: TimeZoneState ) {
    this.updateIsLoggedIn();
    this.loginResponse$.pipe(
      tap(async (loginResponse) => {
        if (loginResponse && loginResponse.isAuth) {
          await this.timeState.getMyTimezone();
        }
      })
    ).subscribe();
  }

  public get isAuth(): boolean {
    return this._isAuthSub.getValue();
  }
  public set isAuth(val: boolean) {
    if (val === this.isAuth) {
      return
    }
    this._isAuthSub.next(val);
  }

  public get authMessage(): string {
    return this._authMessageSub.getValue();
  }
  public set authMessage(val: string) {
    if (val === this.authMessage) {
      return
    }
    this._authMessageSub.next(val);
  }

  public get loginResponse(): LoginResponse {
    return this._loginResponseSub.getValue();
  }
  public set loginResponse(val: LoginResponse) {
    this._loginResponseSub.next(val);
  }

  public set tokenLoginResponse(val: LoginResponse) {
    this._tokenLoginResponseSub.next(val);
    this._loginResponseSub.next(val);
  }

  public get adminData(): AdminData {
    return this._adminDataSub.getValue();
  }
  public set adminData(val: AdminData) {
    this._adminDataSub.next(val);
  }

  public get contactPersona(): Persona {
    return this._contactPersonaSub.getValue();
  }
  public set contactPersona(val: Persona) {
    this._contactPersonaSub.next(val);
  }

  public get authUser(): User {
    return this._authUserSub.getValue();
  }
  public set authUser(val: User) {
    this._authUserSub.next(val);
    if (!!val) {
      this.userWebsocketsService.connect();
      this.permissionsService.flushPermissions();
      this.permissionsService.loadPermissions(val.permissions);
      this.setABGroup(val.aBGroup)
      this.contactPersona = val.contactPersona
      if (val.isAdmin) {
        // this.fetchAdminData();
      }
    } else {
      this.permissionsService.flushPermissions();
    }
  }

  updateIsLoggedIn(): Promise<LoginResponse> {
    const obs = this.http.get<LoginResponse>('/auth/').toPromise();
    obs.then((res: LoginResponse) => {
      this.loginResponse = res;
      if (res?.isAuth) {
        this.authUser = res.user;
        this.isAuth = true;
      } else {
        this.authUser = null;
        this.isAuth = false;
      }
    }, err => {
      this.authUser = null;
    });
    return obs;
  }

  updateIsLoggedInWithToken(authHeader?:string): Promise<LoginResponse> {
    const obs = this.http.get<LoginResponse>('/auth/', {
      headers: new HttpHeaders({
        ...authHeader && {'Authorization': `Bearer ${authHeader}`},
      }),
    }).toPromise();
    obs.then((res: LoginResponse) => {
      this.tokenLoginResponse = res;
      if (res.isAuth) {
        this.authUser = res.user;
        this.isAuth = true;
      } else {
        this.authUser = null;
        this.isAuth = false;
      }
    }, err => {
      this.authUser = null;
    });
    return obs;
  }

  private setLoginToAuth(user: User) {
    this.authUser = user;
    this.authMessage = null;
    this.isAuth = true;
    Sentry.setUser({
      username: user.username,
      email: user.email,
      id: user.id.toString(),
    });
  }

  public async login(username: string, password: string, loginType:'CLIENT'|'EXPERT'): Promise<LoginResponse> {
    const res = await this.http.post<LoginResponse>('/auth/login/', {username, password, loginType}).toPromise();
    if(!res){
      return {isAuth: false, msg: 'Server Error', user: null};
    }
    this.loginResponse = res;
    if (res.isAuth) {
      if(res.msg !== loginType){
        setTimeout(() => {
          this.setLoginToAuth(res.user);
        }, 1500);
      }
      else{
        this.setLoginToAuth(res.user);
      }
    } else {
      this.authMessage = res.msg;
      this.isAuth = false;
    }
    return res
  }

  async logout(): Promise<void> {
    const isExpert = this.authUser.isExpert
    if (this.authUser) {
      this.loginResponse = await this.http.post<LoginResponse>('/auth/logout/', {}).toPromise();
      if (isExpert) {
        this.routingService.navigateToExpertLogin();
      } else {
        this.routingService.navigateToClientLogin();
      }
    } else {
      this.routingService.navigateToClientLogin();
    }
    this.loginResponse = null;
    this.authUser = null;
    this.isAuth = false;
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    const data = {
      oldPassword,
      newPassword,
    }
    await this.http.post<LoginResponse>('/auth/change-password/', data).toPromise();
  }

  popRedirectUrl(): string {
    if (this.redirectUrl) {
      const url = this.redirectUrl;
      this.redirectUrl = null;
      return url;
    }
    return null;
  }

  setABGroup(aBGroup: string): void {
    const permToAdd = `${aBGroup}-group`;
    const otherGroup = aBGroup === 'a' ? 'b' : 'a';
    const permToRemove = `${otherGroup}-group`;
    this.permissionsService.addPermission(permToAdd);
    this.permissionsService.removePermission(permToRemove);
  }

  public async fetchAdminData(): Promise<void> {
    this.adminData = await this.http.get<AdminData>('/api/users/my-admin-data/').toPromise();
  }
}
