import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TimezoneApiService } from '../../apis/timezone-api.service';

@Injectable({
  providedIn: 'root',
})
export class TimeZoneState {
  private timeZone = new BehaviorSubject<string>(null);
  // Expose the timeZone as an Observable
  public timeZone$ = this.timeZone.asObservable();

  constructor(private timezoneApiService: TimezoneApiService) {}

  set timezone(timezone: string) {
    this.timeZone.next(timezone);
  }

  async getMyTimezone(): Promise<string> {
    const response = await this.timezoneApiService.getMyTimezone();
    if (response && response.timezone) {
      this.timezone = response.timezone;
      return response.timezone;
    } else {
      return null;
    }
  }
}
